<template>
  <div class="seting">
    <div class="tilte">
      <div></div>
      <span>{{$t('mine.menuList2[4]')}}</span>
    </div>
    <div class="postPassword">
      <div class="phone">
        <span>{{$t('mine.setting.oldTitle')}}</span>
        <el-input v-model="oldPassword" type="password" :placeholder="$t('mine.setting.oldPs')"></el-input>
      </div>
      <div class="phone">
        <span>{{$t('mine.setting.newTitle')}}</span>
        <el-input v-model="newPassword" type="password" :placeholder="$t('mine.setting.newPs')"></el-input>
      </div>
      <div class="phone">
        <span>{{$t('mine.setting.Title')}}</span>
        <el-input v-model="password" type="password" :placeholder="$t('mine.setting.Ps')"></el-input>
      </div>
      <div class="after">
        <div class="button" @click="setPassord">
          {{$t('button.tijiao')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {changePassword} from '@/api/main.js'
export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      password: ''
    }
  },
  methods: {
    setPassord(){
      if (this.isChinese()) {
          if (!this.oldPassword) {
              this.$message({
                  message: '请先输入密码',
                  type: 'warning'
              });
              return;
          }
          if (!this.newPassword) {
              this.$message({
                  message: '请先输入新密码',
                  type: 'warning'
              });
              return;
          }
          if (this.newPassword!=this.password) {
              this.$message({
                  message: '确认密码和新密码不一致',
                  type: 'warning'
              });
              return;
          }
      } else {//邮箱注册
        if (!this.oldPassword) {
              this.$message({
                  message: 'Please enter your password first',
                  type: 'warning'
              });
              return;
          }
          if (!this.newPassword) {
              this.$message({
                  message: 'Please enter your new password first',
                  type: 'warning'
              });
              return;
          }
          if (this.newPassword!=this.password) {
              this.$message({
                  message: 'Please enter the new password to confirm that the password is inconsistent with the new password',
                  type: 'warning'
              });
              return;
          }
      }
      changePassword({
        oldPassword:this.oldPassword,
        newPassword:this.newPassword
      }).then(res => {
        if(res.code != 0) this.showMessage(res)
        this.oldPassword = ''
        this.newPassword = ''
        this.password = ''
        if(this.isChinese()){
            this.$message({
                message: '修改成功',
                type: 'success'
            });
        }else{
          this.$message({
                message: 'modify successfully',
                type: 'success'
            });
        } 
      })
    }

  }
}
</script>
<style lang="scss">
.seting {
  width: 880px;

  .tilte {

    display: flex;
    align-items: center;
    padding: 9px 0px;
    padding-top: 0px;

    div {
      width: 2px;
      height: 24px;
      background: #333333;
      margin-right: 10px;
    }

    span {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }

  .postPassword {
    padding: 30px;
    border: 1px solid #DDDDDD;

    .phone {
      width: 703px;
      padding: 30px 0px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      .el-input {
        width: 625px !important;
        
      }
      .el-input.is-active .el-input__inner,
      .el-input__inner:focus {
        border-color: #DDDDDD;
        outline: 0;
      }

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;

      }

      textarea {
        width: 600px;
        height: 128px;
        border: 1px solid #DDDDDD;
        resize: none;
        padding: 10px;
      }
    }

    .after {
      margin-top: 40px;
    }

    .after>.button {
      background: #000000;
      border: 1px solid #000000;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      padding: 12px 0px;
      width: 160px;
      text-align: center;
    }

    .after>.button:hover {
      background: #ffff;
      color: #000000;
      transition: all .2s ease .2s;

    }
  }
}
</style>